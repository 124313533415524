import React from 'react';
import { Img } from 'components';

const Companies = () => {
  return (
    <div className="logo-strip">
      <div className="scrolling-container">
        <div className="logo-container">
          <Img className="logo" src="images/img_trace3-inc-logo-vector.png" alt="trace3inc" />
          <Img className="logo" src="images/img_logowithtext.png" alt="radiusai" />
          <Img className="logo" src="images/img_excellous.png" alt="excellous" />
          <Img className="logo" src="images/img_gelogo.png" alt="gelogo" />
          <Img className="logo" src="images/img_mymslogo.jpg" alt="mymslogo" />
          <Img className="logo" src="images/img_musafir.webp" alt="mymslogo" />
          <Img className="logo" src="images/img_trace3-inc-logo-vector.png" alt="trace3inc" />
          <Img className="logo" src="images/img_logowithtext.png" alt="radiusai" />
          <Img className="logo" src="images/img_excellous.png" alt="excellous" />
          <Img className="logo" src="images/img_gelogo.png" alt="gelogo" />
          <Img className="logo" src="images/img_mymslogo.jpg" alt="mymslogo" />
          <Img className="logo" src="images/img_musafir.webp" alt="mymslogo" />
          <Img className="logo" src="images/img_trace3-inc-logo-vector.png" alt="trace3inc" />
          <Img className="logo" src="images/img_logowithtext.png" alt="radiusai" />
          <Img className="logo" src="images/img_excellous.png" alt="excellous" />
          <Img className="logo" src="images/img_gelogo.png" alt="gelogo" />
          <Img className="logo" src="images/img_mymslogo.jpg" alt="mymslogo" />
          <Img className="logo" src="images/img_musafir.webp" alt="mymslogo" />
        </div>
      </div>
    </div>
  );
};

export default Companies;
