import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import ScrollToTop from "components/ScrollToTop";
import usePageTracking from "./usePageTracking";

const Whatourclientssay = React.lazy(() => import("pages/Whatourclientssay"));
const Solutions = React.lazy(() => import("pages/Solutions"));
const WhyCS = React.lazy(() => import("pages/WhyCS"));
const Blog = React.lazy(() => import("pages/Blog"));
const AboutUs = React.lazy(() => import("pages/AboutUs"));
const RevolutionizingCloudManagementPage = React.lazy(() => import("pages/RevolutionizingCloudManagementPage"));
const SustainabilityBlogPage = React.lazy(() => import("pages/SustainabilityBlogPage"));
const OptimizationBlogPage = React.lazy(() => import("pages/OptimizationBlogPage"));
const MulticloudBlogPage = React.lazy(() => import("pages/MulticloudBlogPage"));
const ForecastingBlogPage = React.lazy(() => import("pages/ForecastingBlogPage"));
const AutomatedcostBlogPage = React.lazy(() => import("pages/AutomatedcostBlogPage"));
const IntegratingFinOpsPage = React.lazy(() => import("pages/IntegratingFinOpsPage"));
const LeveragingBlogPage = React.lazy(() => import("pages/LeveragingBlogPage"));
const CostOptimizationPage = React.lazy(() => import("pages/CostOptimizationPage"));

const ProjectRoutes = () => {
  usePageTracking();

  return (
    <React.Suspense fallback={<>Loading...</>}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/whycs" element={<WhyCS />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/whatourclientssay" element={<Whatourclientssay />} />
        <Route path="/revolutionizing-cloud-management" element={<RevolutionizingCloudManagementPage />} />
        <Route path="/sustainability-blog" element={<SustainabilityBlogPage />} />
        <Route path="/optimization-blog" element={<OptimizationBlogPage />} />
        <Route path="/multicloud-blog" element={<MulticloudBlogPage />} />
        <Route path="/forecasting-blog" element={<ForecastingBlogPage />} />
        <Route path="/automatedcost-blog" element={<AutomatedcostBlogPage />} />
        <Route path="/integrating-blog" element={<IntegratingFinOpsPage />} />
        <Route path="/leveraging-blog" element={<LeveragingBlogPage />} />
        <Route path="/costoptimization-blog" element={<CostOptimizationPage />} />
      </Routes>
    </React.Suspense>
  );
};

export default ProjectRoutes;
