import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Text } from "components";
import LeftarrowIcon from "assets/icons/leftarrow.png";
import RightarrowIcon from "assets/icons/rightarrow.png";

const GridComponent = () => {
  const [currentStartIndex, setCurrentStartIndex] = useState(0);

  // Array of grids data
  const gridsData = [
    {
      bgColor: 'bg-blue-A700',
      textColor: 'text-white-A700',
      title: 'Sustainability Reporting with CloudScore: Tracking and Reducing Cloud Carbon Footprint',
      description: 'Uncover the top CloudScore features for boosting cloud sustainability in 2024. Explore innovative tools to track emissions and save costs.',
      link: '/sustainability-blog'
    },
    {
      bgColor: 'bg-gray-50',
      textColor: 'text-gray-900',
      title: 'Optimizing Cloud Infrastructure for AI Workloads with CloudScore',
      description: 'Curious how top companies supercharge their AI performance and slash costs? Find out how CloudScore transforms cloud optimization!',
      link: '/optimization-blog'
    },
    {
      bgColor: 'bg-gray-50',
      textColor: 'text-gray-900',
      title: 'Revolutionizing Cloud Management with CloudScore: Achieving Cost-Effective Optimization',
      description: 'Ever wondered how top companies cut cloud costs dramatically? Dive into how CloudScore makes financial efficiency a reality.',
      link: '/revolutionizing-cloud-management'
    },
    {
      bgColor: 'bg-gray-50',
      textColor: 'text-gray-900',
      title: 'Multi-Cloud Cost Management with CloudScore: Streamlining Efficiency Across Platforms',
      description: 'Exploring Top CloudScore Benefits for 2024: Uncover how this tool simplifies multi-cloud cost management and boosts savings. Discover more now!',
      link: '/multicloud-blog'
    },
    {
      bgColor: 'bg-gray-50',
      textColor: 'text-gray-900',
      title: 'Enhanced Forecasting and Budgeting with CloudScore: Achieving Financial Accuracy in Cloud Management',
      description: 'Unveil CloudScore 2024: Enhance cloud cost management with top-notch forecasting, budgeting, and real-time insights. Discover the game-changer now!',
      link: '/forecasting-blog'
    },
    {
      bgColor: 'bg-gray-50',
      textColor: 'text-gray-900',
      title: 'Automated Cost Management Policies with CloudScore: Streamlining Cloud Efficiency',
      description: 'Unlock the Power of Automated Cloud Cost Management: Discover CloudScore’s game-changing tools to slash expenses and boost efficiency in 2024!',
      link: '/automatedcost-blog'
    },
    {
      bgColor: 'bg-gray-50',
      textColor: 'text-gray-900',
      title: 'Integrating FinOps Best Practices with CloudScore for Enhanced Financial Management',
      description: 'Ready to take control of your cloud finances? CloudScore integrates FinOps to enhance financial visibility, streamline budgeting, and optimize cloud spending for 2024 efficiency.',
      link: '/integrating-blog'
    },
    {
      bgColor: 'bg-gray-50',
      textColor: 'text-gray-900',
      title: 'Leveraging Predictive Analytics in CloudScore for Proactive Cloud Management',
      description: 'Leverage the potential of predictive analytics to anticipate issues, optimize resources, and ensure seamless cloud performance through data-driven insights.',
      link: '/leveraging-blog'
    },
    {
      bgColor: 'bg-gray-50',
      textColor: 'text-gray-900',
      title: 'Cost Optimization for Reserved Instances with CloudScore',
      description: 'Discover how CloudScore helps organizations unlock significant cloud savings through smart reserved instance strategies and real-time optimization.',
      link: '/costoptimization-blog'
    }
    // Add more grid data as needed
  ];

  const handleRotation = (direction) => {
    if (direction === 'left') {
      setCurrentStartIndex((prev) => (prev - 1 + gridsData.length) % gridsData.length);
    } else {
      setCurrentStartIndex((prev) => (prev + 1) % gridsData.length);
    }
  };

  // Ensure to show exactly 4 grids at a time and rotate correctly
  const displayedGrids = [
    ...gridsData.slice(currentStartIndex, currentStartIndex + 4),
    ...gridsData.slice(0, Math.max(0, (currentStartIndex + 4) - gridsData.length))
  ];

  return (
    <div className="relative">
      <div className="gap-8 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 items-center justify-between w-full">
        {displayedGrids.map((grid, index) => (
          <div key={index} className="flex md:flex-col flex-row gap-8 h-[460px] md:h-auto items-stretch justify-start w-auto md:w-full">
            <div className={`${grid.bgColor} border border-gray-300 border-solid flex flex-col gap-4 h-[460px] md:h-auto items-start justify-between p-8 sm:px-5 rounded-lg w-[695px] md:w-full`}>
              <div className="flex flex-col justify-start w-full h-1/2">
                <Text
                  className={`leading-[36.00px] max-w-[552px] md:max-w-full md:text-3xl sm:text-[28px] text-[23.5px] ${grid.textColor}`}
                  size="txtInterBold32"
                >
                  <Link to={grid.link} 
                   className={`${
                    grid.bgColor === 'bg-blue-A700' ? 'hover:text-blue-100' : 'hover:text-blue-500'
                  }`}
                  style={{ textDecoration: 'none' }}>
                    {grid.title}
                  </Link>
                </Text>
              </div>
              <div className="flex flex-col justify-center w-full h-1/2">
                <Text
                  className={`leading-[26.00px] max-w-[552px] md:max-w-full text-base ${grid.textColor}`}
                  size="txtInterRegular16Gray80002"
                >
                  {grid.description}
                </Text>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Left Arrow Icon */}
      <div className="absolute left-[-50px] top-1/2 transform -translate-y-1/2">
        <img
          src={LeftarrowIcon}
          alt="left arrow"
          width={40}
          className="cursor-pointer rounded-full p-2 bg-gray-100 hover:bg-blue-200"
          onClick={() => handleRotation('left')}
        />
      </div>

      {/* Right Arrow Icon */}
      <div className="absolute right-[-50px] top-1/2 transform -translate-y-1/2">
        <img
          src={RightarrowIcon}
          alt="right arrow"
          width={40}
          className="cursor-pointer rounded-full p-2 bg-gray-100 hover:bg-blue-200"
          onClick={() => handleRotation('right')}
        />
      </div>
    </div>
  );
};

export default GridComponent;
